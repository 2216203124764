<template>
    <div class="page">
      <ProfilePartner :userId="$route.params.id"/>
    </div>
</template>

<script>
import ProfilePartner from '@/components/partners/profile/ProfilePartner';

export default {
  name: 'Partner-id',
  components: {
    ProfilePartner,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
